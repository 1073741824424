import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing/PostListing";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import "./listing.css";

class Listing extends React.Component {
  renderPaging() {
    const { currentPageNum, pageCount } = this.props.pageContext;
    const prevPage = currentPageNum - 1 === 1 ? "/blog" : `/blog/${currentPageNum - 1}/`;
    const nextPage = `/blog/${currentPageNum + 1}/`;
    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;

    return (
      <div className="flex justify-evenly my-10">
        {!isFirstPage && <Link className="no-link bg-gray-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" to={prevPage}>← Previous</Link>}
        {!isLastPage && <Link className="no-link bg-gray-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" to={nextPage}>Next →</Link>}
      </div>
    );
  }

  render() {
    const postEdges = this.props.data.allMdx.edges;

    return (
      <Layout>
        <div className="container max-w-5xl mx-auto px-8 lg:px-0">
          <div className="posts-container">
            <Helmet title={config.siteTitle} />
            <SEO />
            <div className="content pt-12">
              <h1 className="has-text-weight-bold is-size-2">Blog</h1>
            </div>
            <PostListing postEdges={postEdges} />
          </div>
          {this.renderPaging()}
        </div>
      </Layout>
    );
  }
}

export default Listing;

/* eslint no-undef: "off" */
export const listingQuery = graphql`
  query ListingQuery($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            coverImage
            date(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
  }
`;
